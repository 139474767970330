import { useEffect } from 'react';

function useOnClickOutside(ref, handler) {
  useEffect(function () {
    var listener = function listener(event) {
      if (!(ref !== null && ref !== void 0 && ref.current) || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return function () {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}

export { useOnClickOutside };

import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import React, { MouseEventHandler } from 'react';

import { FROM_TABLET, MOBILE, TABLET } from '../../../../style/media';
import { StyledComponentProps } from '../../../../types';

interface ContentProps {
  css?: SerializedStyles;
  text: string;
  onClose: MouseEventHandler | undefined;
  isOpen: boolean;
  aboveTheButton: boolean;
}

const OuterContainer = styled.div<StyledComponentProps>`
  position: absolute;
  top: ${({ aboveTheButton }) => (aboveTheButton ? 'auto' : '100%')};
  bottom: ${({ aboveTheButton }) => (aboveTheButton ? '100%' : 'auto')};
  left: 50%;
  transform: ${({ aboveTheButton }) =>
    aboveTheButton ? 'translate(-50%, -10px)' : 'translate(-50%, 10px)'};
  visibility: hidden;
  opacity: 0;
  z-index: 10;

  &[data-open='true'] {
    visibility: visible;
    opacity: 1;
  }

  @media ${MOBILE} {
    position: fixed;
    z-index: 150;
    width: 360px;
    top: 50%;
    left: 50%;
    bottom: auto;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
  }

  ${({ css }) => css}
`;

const AdvertiserDisclosureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #dbdbdb;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  width: 600px;
  background-color: #ffffff;
  color: #000000;
  transition: 0.1s ease-in-out;
  font-size: 14px;
  line-height: 19px;
  color: #2d2d2d;
  text-align: left;

  @media ${TABLET} {
    width: 350px;
    transform: translateX(50px);
  }

  @media ${MOBILE} {
    margin: auto;
    width: auto;
  }

  p {
    strong,
    b {
      font-weight: 600;
    }

    a {
      color: inherit;
      text-decoration: underline;
      font-weight: 600;
    }
  }
`;

const Arrow = styled.div<StyledComponentProps>`
  @media ${FROM_TABLET} {
    width: 10px;
    height: 6px;
    overflow: hidden;
    position: absolute;
    top: ${({ aboveTheButton }) => (aboveTheButton ? 'auto' : '-5px')};
    bottom: ${({ aboveTheButton }) => (aboveTheButton ? '-5px' : 'auto')};
    left: 50%;
    margin-left: -5px;
    transform: ${({ aboveTheButton }) => (aboveTheButton ? 'scaleY(-1)' : 'scaleY(1)')};

    &::before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border: 1px solid #dbdbdb;
      position: absolute;
      transform: rotate(-45deg);
      background: #ffffff;
      top: 2px;
    }
  }

  @media ${TABLET} {
    margin-left: -55px;
    top: ${({ aboveTheButton }) => (aboveTheButton ? 'auto' : '-6px')};
    bottom: ${({ aboveTheButton }) => (aboveTheButton ? '-6px' : 'auto')};
  }

  @media ${MOBILE} {
    display: none;
  }
`;

const CloseButton = styled.div`
  align-self: center;
  margin-top: 10px;
  font-weight: 600;
  border: none;
  text-align: center;
  color: #4d7efb;
  cursor: pointer;
`;

const Content = (props: ContentProps) => {
  const { text, onClose, isOpen, css, aboveTheButton } = props;

  return (
    <OuterContainer
      data-role="advertiser-disclosure-content"
      data-open={isOpen}
      role="dialog"
      data-testid="advertiser-disclosure-content"
      css={css}
      aboveTheButton={aboveTheButton}
    >
      <AdvertiserDisclosureWrapper>
        <Arrow aboveTheButton={aboveTheButton} />
        <div
          data-role="advertiser-disclosure-text"
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        {onClose && (
          <CloseButton
            onClick={onClose}
            data-role="advertiser-disclosure-close"
            data-testid="advertiser-disclosure-close-button"
          >
            Close
          </CloseButton>
        )}
      </AdvertiserDisclosureWrapper>
    </OuterContainer>
  );
};

export default Content;

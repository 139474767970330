import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';

import { MOBILE, TABLET } from '../../style/media';

const ScrollProgress = styled.div<{ scrolled: number }>`
  width: ${({ scrolled }) => scrolled}%;
  height: 9px;
  background: #16ca92;
  z-index: 2;

  @media ${TABLET} {
    height: 6px;
  }

  @media ${MOBILE} {
    height: 4px;
  }
`;

export const ProgressBar = () => {
  const [scrolled, setScrolled] = useState(0);

  useEffect(() => {
    const scrollProgressBar = () => {
      const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
      const height = document.documentElement.scrollHeight - window.innerHeight;
      setScrolled((winScroll / height) * 100);
    };
    window.addEventListener('scroll', scrollProgressBar);
  }, []);

  return <ScrollProgress scrolled={scrolled} id="scroll-progress" />;
};

ProgressBar.displayName = 'ProgressBar';

import styled from '@emotion/styled';
import React from 'react';

import { MOBILE } from '../../../../style/media';
import { StyledComponentProps } from '../../../../types';

const BackdropWrapper = styled.div<StyledComponentProps>`
  @media ${MOBILE} {
    transition: background-color 400ms ease-out 0ms;
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 150;

    ${({ isVisible }) =>
      !isVisible &&
      `
        pointer-events: none;
      background-color: rgba(0, 0, 0, 0);
    `}
  }
`;

interface BackdropProps {
  isVisible: boolean;
  onClick?: () => void;
}

const Backdrop = (props: BackdropProps) => {
  const { isVisible, onClick } = props;

  return (
    <BackdropWrapper
      isVisible={isVisible}
      role="presentation"
      onKeyDown={onClick}
      onClick={onClick}
    />
  );
};

export default Backdrop;

import { SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { useOnClickOutside } from '@xsite-ui/common.use-on-click-outside';
import React, { useCallback, useRef, useState } from 'react';

import { StyledComponentProps } from '../../types';
import { Backdrop, Content } from './components';

interface AdvertiserDisclosureProps {
  text: string;
  buttonText: string;
  buttonCss?: SerializedStyles;
  advertiserDisclosureContainerCss?: SerializedStyles;
  advertiserDisclosureCss?: SerializedStyles;
  shouldOpenOnHover?: boolean;
  showOverlay?: boolean;
  outerContainerClassName?: string;
  aboveTheButton?: boolean;
}

const AdvertiserDisclosureOuter = styled.div<StyledComponentProps>`
  position: relative;
  display: inline-block;

  ${({ css }) => css}
`;

const AdvertiserDisclosureButton = styled.div<StyledComponentProps>`
  color: inherit;
  cursor: pointer;

  ${({ buttonCss }) => buttonCss}
`;

function AdvertiserDisclosure(props: AdvertiserDisclosureProps) {
  const {
    text,
    buttonText,
    advertiserDisclosureContainerCss,
    advertiserDisclosureCss,
    buttonCss,
    showOverlay = true,
    aboveTheButton = false,
  } = props;

  const [isOpen, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const onClose = useCallback(() => setOpen(false), [setOpen]);
  const onToggle = useCallback(() => setOpen((s) => !s), [setOpen]);

  useOnClickOutside(ref, onClose);

  return (
    <AdvertiserDisclosureOuter
      css={advertiserDisclosureContainerCss}
      data-testid="advertiser-disclosure-root"
      ref={ref}
    >
      <Backdrop isVisible={showOverlay && isOpen} onClick={onClose} />
      <AdvertiserDisclosureButton
        buttonCss={buttonCss}
        onClick={onToggle}
        data-role="advertiser-disclosure-label"
      >
        {buttonText}
      </AdvertiserDisclosureButton>
      <Content
        text={text}
        isOpen={isOpen}
        onClose={onClose}
        css={advertiserDisclosureCss}
        aboveTheButton={aboveTheButton}
      />
    </AdvertiserDisclosureOuter>
  );
}

export { AdvertiserDisclosure };

AdvertiserDisclosure.displayName = 'AdvertiserDisclosure';

import styled from '@emotion/styled';
import React from 'react';

import { TO_DESKTOP } from '../../style/media';

const Wrapper = styled.p`
  button {
    font-weight: 600;
    transition: 0.3s ease-in-out;
    text-align: left;

    &:hover {
      opacity: 80%;
    }
  }

  @media ${TO_DESKTOP} {
    margin-bottom: 31px;
  }
`;

interface DoNotSellProps {
  text?: string;
}

export const DoNotSell = ({ text = "Don't Sell My Personal Information" }: DoNotSellProps) => {
  function handleDoNotSellClick() {
    if ((window as any).naturalint_tag) {
      (window as any).naturalint_tag.gdpr.showDoNotSellPrompt();
    }

    if ((window as any).utag) {
      (window as any).utag.gdpr.showDoNotSellPrompt();
    }
  }

  return (
    <Wrapper data-role="dont-sell">
      <button type="button" onClick={handleDoNotSellClick} data-role="dont-sell">
        {text}
      </button>
    </Wrapper>
  );
};

DoNotSell.displayName = 'DoNotSell';

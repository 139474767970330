import hydrate from '@xsite-ui/partial-hydration/hydrate';

import { AdvertiserDisclosure } from '../../../packages/components/advertiser-disclosure/advertiser-disclosure';
import { DoNotSell } from '../../../packages/components/do-not-sell/do-not-sell';
import { ProgressBar } from '../../../packages/components/progress-bar/progress-bar';

(() => {
  hydrate([AdvertiserDisclosure, DoNotSell, ProgressBar]);
})();

export {};
